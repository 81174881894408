import {get, postJ} from "@/request";
import {ILoginParam,ISystemAdmin,IMenu} from "@/apis/core/types";
import {IPageRes} from "@/apis/page";

/**
 * 获取后端菜单
 */
export const getMenuApi = () => get<IMenu[]>("/card/admin/getMenuTree")
/**
 * 用户登录
 * @param id 用户data
 */
export const userLoginApi = (data: ILoginParam) => get("/card/admin/login", data)
/**
 * 获取用户信息
 * @param id
 */
export const getAccountInfoApi = () => get("/card/admin/getAccountInfo")

/**
 * 获取权限
 * @returns
 */
export const getPermission = () => get("/system/admin/getAccountInfo")
/**
 * 修改密码
 * @param pwd
 * @param newPwd
 * @returns
 */
export const changePassword = (pwd: string, newPwd: string) => postJ("/card/admin/changePassword", {pwd, newPwd})
