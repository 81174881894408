import {IConfig} from "@/config/types";

const config: IConfig = {
  project: "gift-admin",
  httpPrefix: "/api",
  timeOut: 10 * 60 * 1000,
  platformName: "藏品出藏礼品卡兑换",
  company: "深圳市榆晟科技有限责任公司",
  uploadUrl: "/party/oss/ordinaryUpload",
  fragmentationUrl: "/party/oss/burstUpload",
  downloadUrl: "/api/party/oss/download",
  videoDownloadUrl:'/api/party/oss/play/video',
  versionName: "v1.0.0"
}

export default config
