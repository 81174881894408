<script>
export default {
  name: 'MTree',
  data () {
    return {
      attr: {}
    }
  },
  created () {
    this.attr = JSON.parse(JSON.stringify(this.$attrs))
  }
}
</script>

<template>
  <el-tree
    v-bind="attr"
  />
</template>

<style lang="scss" scoped></style>
